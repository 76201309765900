import React, { FC, useEffect, useState } from 'react';
import { sectionsList } from './sections';
import { SectionLink } from './types';
import { handleScroll } from 'utils/helpers';
import { Button } from 'components';
import classes from './SideNav.module.css';
interface Props {
  price: number;
  handleSubscribeClick: () => void;
  currencySymbol: string;
  isTrialEnabled: boolean;
}

const Nav: FC<Props> = ({ price, handleSubscribeClick, currencySymbol, isTrialEnabled }) => {
  const [activeSection, setActiveSection] = useState<string | null>();

  const updateNav = () => {
    const sections = document.querySelectorAll('section');

    for (let i = 0; i < sections.length; i++) {
      const windowHeight = window.innerHeight;
      const elementTop = sections[i].getBoundingClientRect().top;
      const elementVisible = windowHeight / 2;

      if (elementTop > 0 && elementTop < windowHeight - elementVisible) {
        const sectionId = sections[i].getAttribute('id');

        setActiveSection(sectionId);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', updateNav);

    return function cleanup() {
      window.removeEventListener('scroll', updateNav);
    };
  }, []);

  const ctaLabel = isTrialEnabled
    ? `Start training for ${currencySymbol + price}`
    : `Subscribe for ${currencySymbol + price}/m`;

  return (
    <div>
      <div className={classes.navContainer}>
        <ul className={classes.navList}>
          {sectionsList.map((section: SectionLink, key) => {
            return (
              <li key={key}>
                <a
                  onClick={() => handleScroll(section.id, 50)}
                  className={`${activeSection === section.id ? classes.active : ''}
                      ${classes.navLink}`}
                >
                  {section.name}
                </a>
              </li>
            );
          })}
        </ul>
        <Button onClick={handleSubscribeClick}>{ctaLabel}</Button>
      </div>
    </div>
  );
};

export default Nav;
