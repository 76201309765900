import React, { FunctionComponent } from 'react';

import classes from './Spinner.module.css';

const spinner: FunctionComponent = () => (
  <div className={classes.multiSpinnerContainer}>
    <div className={classes.multiSpinner}>
      <div className={classes.multiSpinner}>
        <div className={classes.multiSpinner}>
          <div className={classes.multiSpinner}>
            <div className={classes.multiSpinner}>
              <div className={classes.multiSpinner} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default spinner;
