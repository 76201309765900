// Program tags.
// Basic, Intermediate, muscle build, etc.

import React, { FC } from 'react';
import classes from './ProgramTag.module.css';

interface Props {
  tags: string[];
}

const ProgramTag: FC<Props> = ({ tags }) => {
  return (
    <div className={classes.tagContainer}>
      {tags.map((tag, key) => (
        <div key={key} className={classes.tag}>
          {tag}
        </div>
      ))}
    </div>
  );
};

export default ProgramTag;
