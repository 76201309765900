import React, { FC, useState, ChangeEvent } from 'react';

import { Plans, Price, Currency } from 'interfaces/db';

import classes from './Billing.module.css';

type PlansWActive = Plans & { isActive: boolean };
interface Props {
  email: string;
  isYearly: boolean;
  plans: PlansWActive[];
  selectedPlanIndex: number;
  hideChangePlanModal: () => void;
  clientCount: number;
}

const getRegionalPrice = (price: Price[], currency: Currency) => {
  const [regionPrice] = price.filter((el) => el.currency === currency);
  return regionPrice;
};

const PLAN_CHECKOUT_LINKS = {
  cancel_subscription: '',
  basic: 'https://buy.stripe.com/3cs2bqfVYeZ6ejK3cc',
  pro: 'https://buy.stripe.com/4gw6rGfVYeZ65Ne7st',
  elite: 'https://buy.stripe.com/5kAaHWbFI3goa3u9AC',
  enterprise: 'https://buy.stripe.com/eVa7vKh0218g5Ne28b',
  enterprise_level_2: 'https://buy.stripe.com/aEU5nC4dg9EMejK4gk',
};

const ChangePlanModal: FC<Props> = ({
  isYearly,
  email = '',
  plans = [],
  hideChangePlanModal,
  selectedPlanIndex,
  clientCount,
}) => {
  const defaultPlan = plans[selectedPlanIndex];
  const [selectedPlan, setSelectedPlan] = useState(defaultPlan);

  const { id: selectedPlanId, name } = selectedPlan;

  const handleChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const { value } = target;
    const plan = plans.filter(({ id }) => id === +value);
    setSelectedPlan(plan[0]);
  };

  const handlePlanActivation = async () => {
    try {
      const transformedPlanName = name.toLowerCase().replaceAll(' ', '_') as keyof typeof PLAN_CHECKOUT_LINKS;
      const paymentLink = PLAN_CHECKOUT_LINKS[transformedPlanName || 'basic'];

      if (paymentLink) {
        window.location.href = paymentLink + `?prefilled_email=${email}`;
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const { amount: renderedAmount, currency: renderedCurrency } = isYearly
    ? getRegionalPrice(selectedPlan.annualPrice, 'USD')
    : getRegionalPrice(selectedPlan.price, 'USD');

  return (
    <div className={classes.modalContainer}>
      <div className={classes.modalClose}>
        <img
          alt="close"
          onClick={hideChangePlanModal}
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
        />
      </div>

      <div className={classes.changePlanWrapper}>
        <h1 className={classes.planDetails}>
          <sup className={classes.sup}>{renderedCurrency === 'ZAR' ? 'R' : '$'}</sup>
          <span className={classes.price}>{renderedAmount}</span>
          <span className={classes.sub}>/month</span>
        </h1>

        <hr className={classes.separator} />

        <p className={classes.value}>Up to {selectedPlan.maxClients} clients</p>

        <select required onChange={handleChange} className={classes.planSelectField} value={selectedPlanId}>
          {plans.map(({ id: optionId, name }) => (
            <option key={optionId} value={optionId}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <div className={classes.modalButtons}>
        {clientCount > selectedPlan.maxClients ? (
          <p className={classes.secondaryButton}>You have too many clients ({clientCount}) for this plan.</p>
        ) : (
          <button
            className={classes.modalDelete}
            onClick={handlePlanActivation}
            disabled={clientCount > selectedPlan.maxClients}
          >
            Activate Plan
          </button>
        )}
      </div>
    </div>
  );
};

export default ChangePlanModal;
