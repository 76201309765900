import { Testimonial } from 'interfaces/db';
import React, { FC } from 'react';
import classes from './TestimonialCard.module.css';
import { useMediaQuery } from 'utils/hooks';
import Picture from './picture';

interface Props {
  testimonial: Testimonial;
}
const TestimonialCard: FC<Props> = ({ testimonial: { location, name, content, beforeImage, afterImage } }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return isMobile ? (
    <div className={classes.cardContainer}>
      <div className={classes.cardContent}>
        <h3 className={classes.clientName}>{name}</h3>
        <p className={classes.location}>{location}</p>
        <div
          className={`${
            beforeImage?.downloadURL && afterImage?.downloadURL
              ? classes.pics
              : beforeImage?.downloadURL || afterImage?.downloadURL
              ? classes.pic
              : ''
          }`}
        >
          <Picture beforeUrl={beforeImage?.downloadURL} afterUrl={afterImage?.downloadURL} coachName={name} />
        </div>
        <p className={classes.testimonialCopy}>{content}</p>
      </div>
    </div>
  ) : (
    <div className={classes.gridContent}>
      <div
        className={`${
          beforeImage?.downloadURL && afterImage?.downloadURL
            ? classes.left
            : beforeImage?.downloadURL || afterImage?.downloadURL
            ? classes.leftSingle
            : ''
        }`}
      >
        <Picture beforeUrl={beforeImage?.downloadURL} afterUrl={afterImage?.downloadURL} coachName={name} />
      </div>
      <div className={classes.right}>
        <h3 className={classes.clientName}>{name}</h3>
        <p className={classes.location}>{location}</p>
        <p className={classes.testimonialCopy}>{content}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
