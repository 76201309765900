import { Button } from 'components';
import SectionHeader from 'components/SectionHeader';
import React, { FC } from 'react';
import classes from './CTA.module.css';

interface Props {
  isTrialEnabled: boolean;
  handleSubscribeClick: () => void;
  currencySymbol: string;
}

const CTA: FC<Props> = ({ isTrialEnabled, handleSubscribeClick, currencySymbol }) => {
  const ctaLabel = isTrialEnabled ? `Start training for ${currencySymbol}1` : `Subscribe`;

  return (
    <section>
      <div className={classes.CTAContainer}>
        <SectionHeader>
          <span>READY TO GET STARTED?</span>
        </SectionHeader>
        {/* <p>
          Train up to <strong>5 clients free</strong>. No credit card
          required.
        </p> */}

        <div className={classes.buttonContainer}>
          <Button onClick={handleSubscribeClick}>{ctaLabel}</Button>
        </div>
      </div>
    </section>
  );
};

export default CTA;
