import React, { FC } from 'react';
import SectionHeader from 'components/SectionHeader';
import classes from './Testimonials.module.css';

import { Slider } from 'components';

// import TestimonialCard from 'components/MarketplaceCards/TestimonialCard';
import { Testimonial } from 'interfaces/db';

interface Props {
  testimonials: Testimonial[];
  organisation: boolean;
}

const Testimonials: FC<Props> = ({ testimonials, organisation }) => {
  // const isDesktop = useMediaQuery('(min-width: 992px)');

  return (
    <section className={` ${classes.section}`} id="testimonials">
      <SectionHeader>
        <span>{organisation ? 'what our clients have to say' : 'transformations'} </span>
      </SectionHeader>
      <Slider testimonials={testimonials} />
    </section>
  );
};

export default Testimonials;
