import BasicSmallSpinner from 'components/UI/Spinners';
import React, { FC } from 'react';
import classes from './Billing.module.css';

type Props = {
  loading: boolean;
  hidePlanConfirmModal: () => void;
  paymentStatus: string;
  nextBillingDate?: string;
};

const ConfirmPlanModal: FC<Props> = ({ loading, hidePlanConfirmModal, paymentStatus, nextBillingDate }: Props) => (
  <div className={classes.modalContainer}>
    {!loading && (
      <div className={classes.modalClose}>
        <img
          alt="close"
          onClick={hidePlanConfirmModal}
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
        />
      </div>
    )}
    <div className={classes.changePlanWrapper}>
      {loading ? (
        <>
          <BasicSmallSpinner />
          <h4>Updating Plan...</h4>
        </>
      ) : paymentStatus === 'SUCCESS' ? (
        <>
          <h3>Successfully Updated Plan</h3>
          <p>
            Your plan has been successfully updated.
            <br />
            You next payment date is {nextBillingDate}
          </p>
        </>
      ) : paymentStatus === 'FAILED' ? (
        <>
          <h3>Failed to Update Plan</h3>
          <p>We experienced some issues trying to update your plan</p>
        </>
      ) : (
        <>
          <BasicSmallSpinner />
          <h4>Updating Plan...</h4>
        </>
      )}
    </div>
  </div>
);

export default ConfirmPlanModal;
