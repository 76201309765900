import React, { useRef, useEffect } from 'react';

const useOutsideAlerter = (ref, props) => {
  /**
   * if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (props.show === true) {
        props.close();
      }
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

/**
 * Component that activates if you click outside of it
 */
const OutsideAlerter = (props) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props);

  return <div ref={wrapperRef}>{props.children}</div>;
};

export default OutsideAlerter;
