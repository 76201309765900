import React, { FunctionComponent } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import Portal from './Portal';
import classes from './Modal.module.css';

interface Props {
  isModalOpen: boolean;
  openModal: () => void;
  isDynamicWidth?: boolean;
  disableOutsideClicker?: boolean;
}

const Modal: FunctionComponent<Props> = ({
  children,
  openModal,
  isModalOpen,
  isDynamicWidth,
  disableOutsideClicker,
}) => {
  return (
    <>
      {isModalOpen && (
        <Portal selector="#modal">
          <div className={classes.modal}>
            <div className={!isDynamicWidth ? classes.modalInner : classes.modalInnerDynamic}>
              <OutsideClickHandler disabled={!isModalOpen || disableOutsideClicker} onOutsideClick={openModal}>
                {children}
              </OutsideClickHandler>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Modal;
