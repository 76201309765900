import React, { FC } from 'react';
import classes from './Hero.module.css';
import { Button } from 'components';
import { useMediaQuery } from 'utils/hooks';
import { PlanImage } from 'interfaces/db';

interface Props {
  coachName: string;
  planDescription: string;
  price: number;
  programsCount: number;
  heroImageDesktop: PlanImage;
  heroImageMobile: PlanImage;
  handleSubscribeClick: () => void;
  currencySymbol: string;
  organisation: boolean;
  isTrialEnabled: boolean;
}

const Hero: FC<Props> = ({
  coachName,
  planDescription,
  price,
  organisation,
  heroImageDesktop,
  heroImageMobile,
  handleSubscribeClick,
  currencySymbol,
  isTrialEnabled,
}) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const ctaLabel = isTrialEnabled ? `Start training for ${currencySymbol}1` : `Subscribe`;
  return (
    <div className={classes.heroContainer}>
      <img src={isDesktop ? heroImageDesktop.downloadURL : heroImageMobile.downloadURL} alt="hero image" />
      <div className={classes.heroContent}>
        <h1 className={classes.heroTitle}>{coachName}</h1>
        {!isTrialEnabled && (
          <div className={classes.heroSubtitle}>
            Subscribe for <span>{`${currencySymbol + price}/month`}</span>
            <br />
            For unlimited access to {organisation ? 'our' : 'my'} programs
          </div>
        )}

        <p>{planDescription}</p>
        <Button onClick={handleSubscribeClick}>{ctaLabel}</Button>
      </div>
    </div>
  );
};

export default Hero;
