import React, { FC } from 'react';
import SectionHeader from 'components/SectionHeader';
import { Slider } from 'components';
// import { useMediaQuery } from 'utils/hooks';
// import ProgramCard from 'components/MarketplaceCards/ProgramCard';
import { FunnelProgram } from 'interfaces/db';
import classes from './Programs.module.css';

interface Props {
  programs: FunnelProgram[] | undefined;
  coachName: string;
}

const Programs: FC<Props> = ({ programs }) => {
  // const isDesktop = useMediaQuery('(min-width: 992px)');

  return (
    <section className={` ${classes.section}`} id="programs">
      <SectionHeader>
        <span>Exclusive Programs</span>
      </SectionHeader>
      <Slider programs={programs} />
      {/* <div className={classes.cardsWrapper}>
        {!isDesktop &&
          programs?.map((program) => (
            <ProgramCard key={program.programKey} program={program} />
          ))}
      </div> */}
    </section>
  );
};

export default Programs;
