import React, { FC } from 'react';
import TristanPic from 'assets/images/program.png';
import classes from './ProgramCard.module.css';
import ProgramTags from '../../ProgramTag';
import { FunnelProgram } from 'interfaces/db';

interface Props {
  program: FunnelProgram;
}

const Card: FC<Props> = ({ program }) => {
  return (
    <div className={classes.cardContainer}>
      <img src={program?.image || TristanPic} alt="coach avatar" />
      <div className={classes.cardContent}>
        <h3 className={classes.cardTitle}>{program.programName} </h3>
        <span>({program.duration} weeks) </span>
        <h4 className={classes.coachName}>{program.coachName}</h4>
        <ProgramTags tags={[program.equipment, program.experience, program.focusArea]} />

        <p>{program.description}</p>
      </div>
    </div>
  );
};

export default Card;
