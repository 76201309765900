import { SectionLink } from './types';

const sectionsList: SectionLink[] = [
  {
    name: 'Programs',
    id: 'programs',
  },
  {
    name: 'Features',
    id: 'features',
  },
  {
    name: 'Testimonials',
    id: 'testimonials',
  },
  {
    name: 'coach overview',
    id: 'coach-overview',
  },
  {
    name: 'FAQs',
    id: 'faqs',
  },
];

export { sectionsList };
