import React, { FC } from 'react';
import classes from './SectionHeader.module.css';

type Props = {
  featureText?: boolean;
  paragraph?: string;
  // color?: string;
};

const Header: FC<Props> = ({ children, featureText, paragraph }) => {
  return (
    <>
      {featureText && <p className={classes.feature}>features</p>}
      <h2 className={classes.header}>{children}</h2>
      {paragraph && <p className={classes.paragraph}>{paragraph}</p>}
    </>
  );
};

export default Header;
